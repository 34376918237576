import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ApartmentComplexService } from '../../shared/services/apartment-complex.service';

@Component({
  selector: 'app-tracking-modal',
  templateUrl: './tracking-modal.component.html',
  styleUrls: ['./tracking-modal.component.scss']
})

export class TrackingModalComponent implements OnInit {
    @Input() trackingNumbers;
    trackingDetail: any;
    public trackingNumbersArraary: Array<any>;
    constructor(private apartmentComplexService: ApartmentComplexService ) { }

    ngOnInit() {
        this.trackingNumbersArraary = this.trackingNumbers;
    }

    async getTrackingDetail(orderNumber) {
        try {
            this.trackingDetail = await this.apartmentComplexService.getTrackingDetail(orderNumber).toPromise();
            this.trackingNumbers = this.trackingDetail;
            this.ngOnInit();
        } catch (err) {
            throw new Error(err);
        }
    }
}
