import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { ApartmentComplexService } from "../shared/services/apartment-complex.service";
import { Apartment } from "../shared/types/apartment";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationExtras,
  Router,
  NavigationStart,
} from "@angular/router";
@Component({
  selector: "app-reorder-modal",
  templateUrl: "./reorder-modal.component.html",
  styleUrls: ["./reorder-modal.component.scss"],
})
export class ReorderModalComponent implements OnInit, OnChanges {
  validApartmentNumber: any = { isValid: false, message: "" };
  @Output() orderSimilar: EventEmitter<Apartment> =
    new EventEmitter<Apartment>();
  @Output() orderSimilarAddingToCartWithoutReview: EventEmitter<Apartment> =
    new EventEmitter<Apartment>();
  @Input("apartmentComplexID") apartmentComplexID;
  @Input("apartmentId") apartmentId;
  @Input("apartmentType") apartmentType;
  @Input("customItemsAvailable") customItemsAvailable;
  apartment = new Apartment(
    1,
    null,
    "",
    "",
    "",
    "",
    0,
    null,
    null,
    "",
    "",
    null,
    "",
    false,
    1,
    null,
    true,
    false
  );
  isConsulting: boolean = false;
  isPoRequiered = false;  
  public charDontAllowedApply = false;
  public charDontAllowed: string[] = [',', '"', '!', '"', '$', '%', '&', '\'' ,'*', ',', ':', ';', '<', '=', '>', '?', '@', '[', ']', '^', '_', '`', '{', '|', '}', '~', '\\'];
  constructor(private apartmentComplexService: ApartmentComplexService) {}

  ngOnInit() {
    this.apartment.apartmentNumber = "";
    this.apartment.customerPONumber = 'No PO';
    this.apartment.apartmentComplexID = this.apartmentComplexID;
    this.apartment.apartmentType = this.apartmentType;
    this.validateRequierePONumber();   
  }

  ngOnChanges(changes: SimpleChanges) {
    this.apartment.apartmentType = this.apartmentType;
  }

  validateInput() {
    this.charDontAllowed.map(char => {
      if(this.apartment.apartmentNumber.includes(char)) {
        this.charDontAllowedApply = true;
      }
      this.apartment.apartmentNumber = this.apartment.apartmentNumber.replace(char, '');
      while (this.apartment.apartmentNumber.includes(char)) {
        this.apartment.apartmentNumber = this.apartment.apartmentNumber.replace(char, '');
      }
    });
    setTimeout(() => this.charDontAllowedApply = false , 5000);
  }
  
  async validateApartmentNumber(event) {
    try {
      this.isConsulting = true;
      this.validApartmentNumber.isValid = false;
      if (event.target.value.length > 0) {
        this.validApartmentNumber = await this.apartmentComplexService
          .validApartment(event.target.value, this.apartmentComplexID, 0)
          .toPromise();
        this.isConsulting = false;
      }
    } catch (err) {
      this.isConsulting = false;
      throw new Error(err);
    }
  }

  async validateRequierePONumber() {
    try {
      this.isPoRequiered = await this.apartmentComplexService.validateRequierePONumber().toPromise();
    } catch (err) {
      throw new Error(err);
    }
  }
  setAddToCart() {
    this.apartment.partsOrder = !this.apartment.partsOrder;    
  }
}
