// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  api: 'https://qwikkitordering-uat.azurewebsites.net/',
  packagePlanningAPI: 'https://packageplanning-uat.azurewebsites.net/api/PackagePlanning',
  schedulingAPI: 'https://qwikkitproductionscheduling-uat.azurewebsites.net/api/',
  signalRServerURL: 'https://qwikkitsignalr-uat.azurewebsites.net',
  metricFunction: 'https://cinchhealth-uat.azurewebsites.net/api/LogMetricFunction?code=B4hfF/HLCMj6PgaVwsVbq1J/RqdIGFzwJqUVJqpxKghKb3LpSL6L2w=='
};
